import React, { useEffect } from 'react';
import { authService, ticketMessageService, fileService } from '../../_services'
import { useTranslation } from "react-i18next";
import { Card, Button, Modal, Form } from 'react-bootstrap'
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';
import { SERVER_URL } from '../../config';

function Messages({ticket}) {

	const [messages, setMessages] = React.useState([])
	const [showModal, setShowModal] = React.useState(false)
	const [ticketMessage, setTicketMessage] = React.useState({type: 'UNDER_INVESTIGATION'})
	const [files, setFiles] = React.useState([])
	const {t} = useTranslation('common');

	useEffect(() => {
		setMessages(ticket.messages)
    }, [])

	const formatDate = function(date) {
		return moment(date).format('YYYY-MM-DD HH:mm')
	}

	const formatMessageType = function(message) {
		switch (message.type) {
			case 'FROM_WHISTLEBLOWER':
				return t('ticketmessage.types.fromwhistleblower')
			case 'TO_WHISTLEBLOWER':
				return t('ticketmessage.types.towhistleblower', { customer: message.customerName })
		}
	}

	const handleMessageChange = (e) => {
	    const target = e.target
	    const name = target.name
	    const value = target.type === 'checkbox' ? target.checked : target.value;
	    setTicketMessage( prevTicketMessage => ({
	        ...prevTicketMessage,
	        [name]: value
	    }));
	}

	const handleFileChange = (e) => {
		var files = []
		for (var i = 0; i < e.target.files.length; i++) {
			var targetFile = e.target.files[i]
			console.log(targetFile)
			const file = {
				id: uuidv4(),
				customerId: ticket.customerId,
				name: targetFile.name,
				data: null,
				dataType: targetFile.type
			}
			console.log(file)
			files.push(file)
			const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                let binaryString = event.target.result;
                file.data = btoa(binaryString)
            });
			reader.readAsBinaryString(targetFile)
		}
		setFiles(files)
	}

	const submit = function(event) {
		console.log('submit')

		event.preventDefault()
        event.stopPropagation()

		ticketMessage.customerId = ticket.customerId
		ticketMessage.userId = authService.getCurrentUser().id
		ticketMessage.ticket = ticket.id
		ticketMessage.type = 'TO_WHISTLEBLOWER'

		ticketMessage.files = files.map(file => ({...file, data: null}))

		ticketMessageService.save(ticketMessage).then(data =>  {
			files.forEach(file => {
				fileService.update(file.id, file)
			});

			messages.push(data)

		    setTicketMessage({})
		})

		setShowModal(false)
	}

	const onHide = () => {
		console.log('onHide')
		setShowModal(false)
		//setBackdrop(false)
		document.body.className = ""
		document.body.style = '';
    }

	return (
		<div style={{paddingTop: '15px'}}>
			{ ticket &&
				<>
					<div className="clearfix">
						<div style={{'float': 'left'}}>
							<h4>
								{t('ticketmessage.label')}
							</h4>
						</div>
						<div style={{'float': 'right'}}>
							<Button
					            style={{ width: "100px" }}
					            onClick={() => setShowModal(true)}
					            title={t("crudtable.add")}
					            size="md"
					            color="info"
					            variant="outline-secondary">
					            {t("crudtable.add")} <FontAwesomeIcon icon={faPlus}/>
					        </Button>
						</div>
					</div>
					{ ticket.messages.length == 0 &&
						<Card style={{margin: '10px'}}>
							<Card.Body>
								{t("ticketmessage.nomessages")}
							</Card.Body>
						</Card>
					}
					{ ticket.messages.sort((a, b) => b.dateCreated.localeCompare(a.dateCreated)).map(message =>
						<Card style={{margin: '10px'}}  key={message.id}>
							<Card.Body>
								<div>
									<b>{t('ticketmessage.columns.datecreated')}:</b> {formatDate(message.dateCreated)}
								</div>
								<div>
									<b>{t('ticketmessage.columns.type')}:</b> {formatMessageType(message)}
								</div>
								{ message.type == 'TO_WHISTLEBLOWER' &&
									<div>
										<b>{t('ticketmessage.columns.from')}:</b> {message.user.username || '-'}
									</div>
								}
								{ (message.files && message.files.length > 0) &&
									<div>
										<b>{t('ticketmessage.columns.files')}:</b>
										<ul style={{marginBottom: 0}}>
										{ message.files.map(file => {
											return <li key={file.id}>
												<a href={SERVER_URL + '/files/' + file.id} target="_blank">
													{file.name}
												</a>
											</li>
										})}
										</ul>
									</div>
								}
								<div>
									<b>{t('ticketmessage.columns.message')}:</b>
									<br/>
									{message.message}
								</div>
							</Card.Body>
						</Card>
					)}
				</>
			}
			<Modal
				size="lg"
				show={showModal}
				backdrop={false}
				onHide={onHide}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('ticketmessage.label')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form id="data-form" onSubmit={submit}>
			            <Form.Group controlId="message">
			                <Form.Label>
			                    {t('ticketmessage.columns.message')} *
			                </Form.Label>
			                <TextareaAutosize className="form-control" placeholder={t('ticketmessage.columns.message')} minRows={5} name="message" onChange={handleMessageChange}/>
			            </Form.Group>
			            <Form.Group controlId="file">
			                <Form.Label>
			                    {t('ticketmessage.columns.files')}
			                </Form.Label>
			                <Form.Control type="file" multiple onChange={handleFileChange}/>
			            </Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={onHide}>
						{t('crudtable.cancel')}
					</Button>
					<Button form='data-form' type="submit" variant="primary">
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
            </Modal>
		</div>
    )
}

export default Messages;