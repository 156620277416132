import React, {useState, useEffect} from 'react';
import { ticketService, authService, ticketEventService } from '../../_services'
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { Tabs, Tab, Button, Modal, Form, Card } from 'react-bootstrap'
import { SERVER_URL } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons'
import moment from "moment";
import Members from './members'
import Messages from './messages'
import CustomerMessages from './customermessages'
import Events from './events'
import Items from './items'

function TicketDetails() {

	const [ticket, setTicket] = useState(null)
	const [showCompleteModal, setShowCompleteModal] = useState(false)
	const [showVisibilityModal, setShowVisibilityModal] = useState(false)
	const [showBackdrop, setShowBackdrop] = useState(false);
	const [completionState, setCompletionState] = useState({correctWhistleblowing: true, reasonForCompletion: ''});
	const [visibilityState, setVisibilityState] = useState({visibleToCustomer: false, itemsVisibleToCustomer: false, eventsVisibleToCustomer: false, messagesVisibleToCustomer: false});
	const {uuid} = useParams();
	const {t} = useTranslation('common');

	useEffect(() => {
		ticketService.show(uuid).then(ticket => {
            setTicket(ticket)
            setVisibilityState({
                visibleToCustomer: ticket.visibleToCustomer,
                itemsVisibleToCustomer: ticket.itemsVisibleToCustomer,
                eventsVisibleToCustomer: ticket.eventsVisibleToCustomer,
                messagesVisibleToCustomer: ticket.messagesVisibleToCustomer
            })
            ticketEventService.markAsRead(ticket.id).then(data =>  {
				console.log('markAsRead')
			})
        })
    }, [])

    const onHideCompleteModal = () => {
		setShowCompleteModal(false)
		setShowBackdrop(false)
		document.body.className = ""
		document.body.style = '';
    }

    const onHideVisibilityModal = () => {
		setShowVisibilityModal(false)
		setShowBackdrop(false)
		document.body.className = ""
		document.body.style = '';
    }

    const onChange = (e) => {
		const target = e.target
	    const name = target.name
	    const value = target.type === 'checkbox' ? target.checked : target.value;
	    setCompletionState( prevState => ({
	        ...prevState,
	        [name]: value
	    }));
    }

    const onChangeVisibility = (e) => {
		const target = e.target
	    const name = target.name
	    const value = target.type === 'checkbox' ? target.checked : target.value;
	    setVisibilityState( prevState => ({
	        ...prevState,
	        [name]: value
	    }));
    }

    const complete = () => {
        setShowCompleteModal(false)
        var map = {
            id: ticket.id,
            correctWhistleblowing: completionState.correctWhistleblowing,
            reasonForCompletion: completionState.reasonForCompletion
        }
		ticketService.complete(ticket.id, map).then(ticket => {
            setTicket(ticket)
        })
    }

    const updateVisibility = () => {
        setShowVisibilityModal(false)
        var map = {
            ...visibilityState,
            id: ticket.id
        }
		ticketService.update(ticket.id, visibilityState).then(ticket => {
            setTicket(ticket)
            setVisibilityState({
                visibleToCustomer: ticket.visibleToCustomer,
                itemsVisibleToCustomer: ticket.itemsVisibleToCustomer,
                eventsVisibleToCustomer: ticket.eventsVisibleToCustomer,
                messagesVisibleToCustomer: ticket.messagesVisibleToCustomer
            })
        })
    }

	return (
		<div>
			{ ticket &&
				<>
					<h2>
						{ticket.name}
					</h2>
					{ authService.hasRoles(['ROLE_ADMIN']) &&
						<Button
				            onClick={() => setShowVisibilityModal(true)}
				            title={t("ticket.changevisibilitytocustomer")}
				            size="md"
				            color="info"
				            variant="outline-secondary">
				            {t("ticket.changevisibilitytocustomer")} <FontAwesomeIcon icon={faEye}/>
				        </Button>
					}
					{ ticket.completed &&
						<Card style={{margin: '10px'}}>
							<Card.Body>
								<b>{t("ticket.completed")}</b>: {moment(ticket.dateCompleted).format('YYYY-MM-DD HH:mm')}
								<br/>
								<b>{t("ticket.correctwhistleblowing")}</b>: {ticket.correctWhistleBlowing ? t('crudtable.yes') : t('crudtable.no')}
								<br/>
								<b>{t("ticket.reasonforcompletion")}</b>: {ticket.reasonForCompletion}
							</Card.Body>
						</Card>
			        }
					{ !ticket.completed && authService.hasRoles(['ROLE_ADMIN']) &&
						<Button
				            onClick={() => setShowCompleteModal(true)}
				            title={t("ticket.complete")}
				            size="md"
				            color="info"
				            variant="outline-secondary">
				            {t("ticket.complete")} <FontAwesomeIcon icon={faCheck}/>
				        </Button>
			        }
			        { authService.hasRoles(['ROLE_ADMIN']) &&
						<Members ticket={ticket}></Members>
					}
					<Tabs>
						{ (authService.hasRoles(['ROLE_ADMIN']) || ticket.eventsVisibleToCustomer) &&
							<Tab eventKey="events" title={t('event.label')}>
								<Events ticket={ticket}></Events>
							</Tab>
						}
						{ (authService.hasRoles(['ROLE_ADMIN']) || ticket.messagesVisibleToCustomer) &&
							<Tab eventKey="messages" title={t('ticketmessage.label')}>
								<Messages ticket={ticket}></Messages>
							</Tab>
						}
						{ false &&
							<Tab eventKey="customermessages" title={t('customermessage.label')}>
								<CustomerMessages ticket={ticket}></CustomerMessages>
							</Tab>
						}
						{ (authService.hasRoles(['ROLE_ADMIN']) || ticket.itemsVisibleToCustomer) &&
							<Tab eventKey="items" title={t('item.answers')}>
								<Items ticket={ticket}></Items>
							</Tab>
						}
					</Tabs>
				</>
			}
			<Modal
				style={{overflow: 'scroll'}}
				size="lg"
				show={showCompleteModal}
				backdrop={showBackdrop}
				onShow={() => console.log('onShow')}
				onHide={onHideCompleteModal}>
				<Modal.Header closeButton>
					<Modal.Title>{t('ticket.complete')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group controlId="correctWhistleblowing">
						<Form.Check
							name="correctWhistleblowing"
							type="checkbox"
							label={t('ticket.correctwhistleblowing')}
							checked={completionState.correctWhistleblowing}
							onChange={onChange}
						/>
					</Form.Group>
					<Form.Group controlId="reasonForCompletion">
						<Form.Label>
		                    {t('ticket.reasonforcompletion')}
		                </Form.Label>
						<Form.Control
							name="reasonForCompletion"
							as="textarea"
							rows={3}
							onChange={onChange}/>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button
			            onClick={complete}
			            title={t("ticket.complete")}
			            size="md"
			            color="info"
			            variant="outline-secondary">
			            {t("ticket.complete")} <FontAwesomeIcon icon={faCheck}/>
			        </Button>
				</Modal.Footer>
			</Modal>
			<Modal
				style={{overflow: 'scroll'}}
				size="lg"
				show={showVisibilityModal}
				backdrop={showBackdrop}
				onShow={() => console.log('onShow')}
				onHide={onHideVisibilityModal}>
				<Modal.Header closeButton>
					<Modal.Title>{t('ticket.changevisibilitytocustomer')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group controlId="visibleToCustomer">
						<Form.Check
							name="visibleToCustomer"
							type="checkbox"
							label={t('ticket.visibletocustomer')}
							checked={visibilityState.visibleToCustomer}
							onChange={onChangeVisibility}
						/>
					</Form.Group>
					<div style={{paddingLeft: '20px'}}>
						<Form.Group controlId="itemsVisibleToCustomer">
							<Form.Check
								name="itemsVisibleToCustomer"
								type="checkbox"
								label={t('ticket.itemsvisibletocustomer')}
								checked={visibilityState.itemsVisibleToCustomer}
								onChange={onChangeVisibility}
								disabled={!visibilityState.visibleToCustomer}
							/>
						</Form.Group>
						<Form.Group controlId="eventsVisibleToCustomer">
							<Form.Check
								name="eventsVisibleToCustomer"
								type="checkbox"
								label={t('ticket.eventsvisibletocustomer')}
								checked={visibilityState.eventsVisibleToCustomer}
								onChange={onChangeVisibility}
								disabled={!visibilityState.visibleToCustomer}
							/>
						</Form.Group>
						<Form.Group controlId="messagesVisibleToCustomer">
							<Form.Check
								name="messagesVisibleToCustomer"
								type="checkbox"
								label={t('ticket.messagesvisibletocustomer')}
								checked={visibilityState.messagesVisibleToCustomer}
								onChange={onChangeVisibility}
								disabled={!visibilityState.visibleToCustomer}
							/>
						</Form.Group>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
			            onClick={onHideVisibilityModal}
			            title={t("crudtable.cancel")}
			            size="md"
			            color="info"
			            variant="secondary">
			            {t("crudtable.cancel")}
			        </Button>
					<Button
			            onClick={updateVisibility}
			            title={t("crudtable.ok")}
			            size="md"
			            color="info"
			            variant="primary">
			            {t("crudtable.ok")}
			        </Button>
				</Modal.Footer>
			</Modal>
		</div>
    )
}

export default TicketDetails;