import api from './api.service'

export const exportService = {
    doExport
};

const BASE_URL = '/export'

function doExport(map) {
    return api.post(BASE_URL, map)
}
