import React, {useState, useEffect} from 'react';
import CrudTable from '../table/crudtable'
import { Modal, Row, Col } from 'react-bootstrap'
import { authService, customerMessageService, environmentService } from '../../_services'
import CustomerMessageForm from './customermessageform.component'
import { useTranslation } from "react-i18next";
import moment from "moment";
import { SERVER_URL } from '../../config';

function CustomerMessage() {
	const [message, setMessage] = useState(null);
	const [showReadModal, setShowReadModal] = useState(false);
	const [backdrop, setBackdrop] = useState(false);
	const [environment, setEnvironment] = useState(null);
	const {t} = useTranslation('common');

	const initialState = {
		customerId: -1,
		userId: -1,
		type: 'TO_CUSTOMER',
		title: '',
		message: '',
		files: [],
		allUsers: true,
		customerMessageUsers: []
	}

	useEffect(() => {
		setEnvironment(localStorage.getItem('environment'))
    }, [])

    const dateFormatter = function(cell, row) {
		return moment(cell).format('YYYY-MM-DD HH:mm')
	}

	const fromFormatter = function(cell, row) {
		return row.type == 'TO_CUSTOMER' ? environmentService.getCompanyName(environment) : row.customerName
	}

	const toFormatter = function(cell, row) {
		var text = ''
		if (row.type == 'FROM_CUSTOMER') {
			text += environmentService.getCompanyName(environment)
		} else {
			text += row.customerName
			if (authService.hasRoles(['ROLE_ADMIN'])) {
				if (row.allUsers) {
					text += " (" + t('customermessage.columns.allusers') + ")"
				} else if (row.customerMessageUsers && row.customerMessageUsers.length > 0) {
					row.customerMessageUsers.sort((a, b) => a.username.localeCompare(b.username))
					text += " (" + row.customerMessageUsers.map(customerMessageUser => customerMessageUser.username).join(',') + ")"
				}
			}
		}
		return text
	}

	const titleFormatter = function(cell, row) {
		return <a href="javascript:void(0)" onClick={event => readMessage(row)}>{cell}</a>
	}

	const columns = [
		{
			dataField: 'dateCreated',
			text: t('customermessage.columns.datecreated'),
			style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '40px' },
			formatter: dateFormatter,
			filterValue: dateFormatter,
			sort: true
		},
		{
			dataField: 'from',
			text: t('customermessage.columns.from'),
			style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '40px' },
			formatter: fromFormatter,
			sort: true
		},
		{
			dataField: 'to',
			text: t('customermessage.columns.to'),
			style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '40px' },
			formatter: toFormatter,
			sort: true
		},
		{
			dataField: 'title',
			text: t('customermessage.columns.title'),
			formatter: titleFormatter,
			sort: true
		}
	]

	const readMessage = (message) => {
		setShowReadModal(true)
		setMessage(message)
		if (!message.read) {
			customerMessageService.markAsRead(message.id)
		}
    }

	const onHideReadModal = () => {
		setShowReadModal(false)
		setBackdrop(false)
    }

	return (
		<div>
			<h2>
				{ authService.hasRoles(['ROLE_ADMIN']) &&
                    t('navbar.customercommunication')
                }
				{ !authService.hasRoles(['ROLE_ADMIN']) &&
                    t('navbar.communication')
                }
			</h2>
			<CrudTable
				service={customerMessageService}
				columns={columns}
				initialState={initialState}
				form={<CustomerMessageForm />}
				sort={{dataField: 'dateCreated', order: 'desc'}}
				modalSize="lg"
				editable={false}
			/>
			<Modal
				style={{overflow: 'scroll'}}
				size="lg"
				show={showReadModal}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHideReadModal}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						{message ? message.title : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ message &&
						<>
							<Row>
								<Col md="6">
									<b>{t('customermessage.columns.from')}:</b>
									<br/>
									{fromFormatter(message.customerName, message)}
								</Col>
								<Col md="6">
									<b>{t('customermessage.columns.to')}:</b>
									<br/>
									{toFormatter(message.customerName, message)}
								</Col>
								{ message.type == 'TO_CUSTOMER' && authService.hasRoles(['ROLE_ADMIN']) &&
									<Col md="12">
										<b>{t('customermessage.columns.read')}:</b>
										<br/>
										{message.read ? t('crudtable.yes') : t('crudtable.no')}
									</Col>
								}
								<Col md="12">
									<b>{t('customermessage.columns.message')}:</b>
									<br/>
									{message.message}
								</Col>
								{ message.ticket &&
									<Col md="12">
										<b>{t('customermessage.columns.ticket')}:</b>
										<br/>
										<a href={'ticket/' + message.ticket.id}>{message.ticket.name}</a>
									</Col>
								}
								{ message.files && message.files.length > 0 &&
									<Col md="12">
										<b>{t('customermessage.columns.files')}:</b>
										<br/>
										{ message.files.map(file => {
											return <li key={file.id}>
												<a href={SERVER_URL + '/files/' + file.id} target="_blank">
													{file.name}
												</a>
											</li>
										})}
									</Col>
								}
							</Row>
						</>
					}
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default CustomerMessage;