import React, { useState, useEffect } from 'react';
import { authService, userService } from '../_services'
import { Row, Col, Button, Form, Modal } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { alertActions } from '../_actions/alert.actions'

function Profile() {

	const [user, setUser] = useState({email: '', sendTicketAlerts: false})
	const [validated, setValidated] = useState(false);
	const [passwordValidated, setPasswordValidated] = useState(false);
	const [passwordState, setPasswordState] = useState({password: '', repeatPassword: ''})
	const [showPasswordModal, setShowPasswordModal] = useState(false);
	const [backdrop, setBackdrop] = useState(false);
	const {t} = useTranslation('common');
	const dispatch = useDispatch();

	useEffect(() => {
		userService.showSelf().then(response => {
			setUser(response)
		})
    }, [])

    const handleEmailChange = (event) => {
        var value = event.target.value
        setUser( prevUser => ({
            ...prevUser,
	        ['email']: value
	    }));
    }

    const handleSendTicketAlertsChange = (event) => {
        var value = event.target.checked
        setUser( prevUser => ({
            ...prevUser,
	        ['sendTicketAlerts']: value
	    }));
    }

    const handleSubmit = (event) => {
	    event.preventDefault();
	    const form = event.currentTarget;
	    if (form.checkValidity() === false) {
	      event.stopPropagation();
	      setValidated(true);
	    } else {
	      userService.updateSelf(user.id, user.email, user.sendTicketAlerts).then(response => {
			dispatch(alertActions.success(t('alerts.updatesuccessful')))
		  })
	      setValidated(false);
	    }
	}

	const onHidePasswordModal = () => {
		setShowPasswordModal(false)
		setBackdrop(false)
    }

    const handlePasswordChange = (event) => {
        let name = event.target.name
        let value = event.target.value
        setPasswordState(prevState => ({
            ...prevState,
	        [name]: value
	    }));
    }

    const handlePasswordSubmit = (event) => {
	    event.preventDefault();
	    const form = event.currentTarget;
	    if (form.checkValidity() === false) {
			event.stopPropagation()
	        setPasswordValidated(true)
	    } else {
	        userService.updatePassword(user.id, passwordState).then(response => {
				dispatch(alertActions.success(t('alerts.updatesuccessful')))
		    })
		    setPasswordState({password: '', repeatPassword: ''})
		    setPasswordValidated(false)
	        setShowPasswordModal(false)
	    }
	};

	const getPattern = (password) => {
		if (!passwordsMatch()) {
			return "^$"
		}

		if (!containsCapitalLetter(password)) {
			return "^$"
		}

		if (!containsSpecialCharacter(password)) {
			return "^$"
		}

		return null
	}

	const passwordsMatch = () => {
		return passwordState.password == passwordState.repeatPassword
	}

	const containsCapitalLetter = (password) => {
		return /[A-ZÄÖÅ]/.test(password)
	}

	const containsSpecialCharacter = (password) => {
		var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
		return format.test(password)
	}

    return (
        <div>
		    <Row>
		        <Col sm="4">
		            <h2>
		                {t('profile.label')}
		            </h2>
		            <Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
						<Form.Group controlId="email">
				            <Form.Label>
				                {t('user.columns.email')}
				            </Form.Label>
				            <Form.Control type="email" placeholder={t('user.columns.email')} name="email" value={user.email} onChange={handleEmailChange} />
				        </Form.Group>
				        <Form.Group controlId="sendTicketAlerts">
				            <Form.Check type="checkbox" label={t('user.columns.sendticketalerts')} name="sendTicketAlerts" checked={user.sendTicketAlerts} onChange={handleSendTicketAlertsChange} />
				        </Form.Group>
				        <Button type="submit">
				            {t('profile.update')}
				        </Button>
			        </Form>
		        </Col>
		    </Row>
		    <Row style={{marginTop: '40px'}}>
		        <Col sm="4">
		            <Button onClick={() => setShowPasswordModal(true)}>
			            {t('profile.changepassword')}
			        </Button>
		        </Col>
		    </Row>
		    <Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={showPasswordModal}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHidePasswordModal}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('profile.changepassword')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{marginBottom: '40px'}}>
						{t('profile.help')}
					</div>
					<Form id="password-form" noValidate onSubmit={handlePasswordSubmit} validated={passwordValidated}>
				        <Form.Group controlId="password">
				            <Form.Label>
				                {t('profile.password')}
				            </Form.Label>
				            <Form.Control
				                type="password"
				                placeholder={t('profile.password')}
				                name="password"
				                value={passwordState.password}
				                onChange={handlePasswordChange}
				                required={true}
				                minLength={8}
				                pattern={getPattern(passwordState.password)}
				            />
				            <Form.Control.Feedback type="invalid">
				                <ul>
					                { passwordState.password == '' &&
					                    <li>
					                        {t('profile.emptypassword')}
					                    </li>
					                }
					                { passwordState.password.length < 8 &&
					                    <li>
					                        {t('profile.shortpassword')}
					                    </li>
					                }
					                { !passwordsMatch() &&
					                    <li>
					                        {t('profile.mismatchingpasswords')}
					                    </li>
					                }
					                { !containsCapitalLetter(passwordState.password) &&
					                    <li>
					                        {t('profile.nocapitalletter')}
					                    </li>
					                }
					                { !containsSpecialCharacter(passwordState.password) &&
					                    <li>
					                        {t('profile.nospecialcharacter')}
					                    </li>
					                }
				                </ul>
                            </Form.Control.Feedback>
				        </Form.Group>
				        <Form.Group controlId="repeatpassword">
				            <Form.Label>
				                {t('profile.repeatpassword')}
				            </Form.Label>
				            <Form.Control
				                type="password"
				                placeholder={t('profile.repeatpassword')}
				                name="repeatPassword"
				                value={passwordState.repeatPassword}
				                onChange={handlePasswordChange}
				                required={true}
				                minLength={8}
				                pattern={getPattern(passwordState.repeatPassword)}
				            />
				            <Form.Control.Feedback type="invalid">
				                <ul>
					                { passwordState.repeatPassword == '' &&
					                    <li>
					                        {t('profile.emptypassword')}
					                    </li>
					                }
					                { passwordState.repeatPassword.length < 8 &&
					                    <li>
					                        {t('profile.shortpassword')}
					                    </li>
					                }
					                { !passwordsMatch() &&
					                    <li>
					                        {t('profile.mismatchingpasswords')}
					                    </li>
					                }
					                { !containsCapitalLetter(passwordState.repeatPassword) &&
					                    <li>
					                        {t('profile.nocapitalletter')}
					                    </li>
					                }
					                { !containsSpecialCharacter(passwordState.repeatPassword) &&
					                    <li>
					                        {t('profile.nospecialcharacter')}
					                    </li>
					                }
				                </ul>
                            </Form.Control.Feedback>
				        </Form.Group>
			        </Form>
				</Modal.Body>
				<Modal.Footer>
					<Button type="submit" form="password-form">
			            {t('profile.update')}
			        </Button>
				</Modal.Footer>
			</Modal>
	    </div>
    )
}

export default Profile;