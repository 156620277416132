import React, { useState, useEffect } from 'react';
import Customer from './customer/customer'
import User from './user'
import SwitchUser from './switchuser'
import SwitchBack from './switchback'
import IntroText from './introtext'
import PrivacyPolicy from './privacypolicy'
import Form from './form'
import Template from './template'
import { TicketList, TicketDetails } from './ticket'
import CustomerMessage from './customermessage'
import Export from './export'
import Home from './home.component'
import Profile from './profile.component'
import Logout from './logout.component'
import Forbidden from './forbidden.component'
import { ProtectedRoute } from "./protected.route";
import PageNavbar from "./navbar.component"
import AlertComponent from './alert.component'
import { Switch, Route } from 'react-router-dom';
import { Col, Container } from 'react-bootstrap'
import { environmentService } from '../_services'

function DefaultContainer() {
	useEffect(() => {
		console.log('useEffect')
		var existingEnvironment = localStorage.getItem('environment')
		console.log(existingEnvironment)
		if (existingEnvironment) {
			document.title = environmentService.getTitle(existingEnvironment)
		}
	}, [])

    return (
      <>
      <PageNavbar />
      <Container fluid>
        <Col md={{ span: 12, offset: 0 }}>
          <AlertComponent />
          <Switch>
              <ProtectedRoute exact path="/" component={Home}/>
              <ProtectedRoute exact path="/profile" component={Profile}/>
              <ProtectedRoute exact path="/customer" component={Customer} permission={["ROLE_ADMIN"]}/>
              <ProtectedRoute exact path="/user" component={User} permission={["ROLE_ADMIN"]}/>
              <ProtectedRoute exact path="/switchuser" component={SwitchUser} permission={["ROLE_SUPER_ADMIN"]}/>
              <ProtectedRoute exact path="/switchback" component={SwitchBack} permission={["ROLE_SWITCHED_USER"]}/>
              <ProtectedRoute exact path="/introtext" component={IntroText} permission={["ROLE_INTRO_TEXT_READ"]}/>
              <ProtectedRoute exact path="/privacypolicy" component={PrivacyPolicy} permission={["ROLE_PRIVACY_POLICY_READ"]}/>
              <ProtectedRoute exact path="/form" component={Form} permission={["ROLE_FORM_READ"]}/>
              <ProtectedRoute exact path="/template" component={Template} permission={["ROLE_TEMPLATE_WRITE"]}/>
              <ProtectedRoute exact path="/ticket" component={TicketList} permission={["ROLE_TICKET_READ"]}/>
              <ProtectedRoute exact path="/ticket/:uuid" component={TicketDetails} permission={["ROLE_TICKET_READ"]}/>
              <ProtectedRoute exact path="/customermessage" component={CustomerMessage} permission={["ROLE_CUSTOMER_MESSAGE_READ"]}/>
              <ProtectedRoute exact path="/export" component={Export} permission={["ROLE_ADMIN"]}/>
              <Route exact path="/logout" component={Logout}/>
              <Route exact path="/forbidden" component={Forbidden}/>
              <Route path="*" component={() => "404 NOT FOUND"}/> 
          </Switch>
        </Col>
      </Container>
      </>
    )
}

 export default DefaultContainer;