import React, { useState, useEffect } from 'react';
import Form from "react-validation/build/form";
import { Container, Row, Jumbotron, Col, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import AlertComponent from './alert.component'
import { useTranslation } from "react-i18next";
import { resetPasswordService, environmentService } from '../_services'

function ResetPassword() {

	const [username, setUsername] = useState('')
	const [submitted, setSubmitted] = useState(false)
	const [loading, setLoading] = useState(false)
	const [environment, setEnvironment] = useState('')
	const {t} = useTranslation('common');

	function getLogo() {
        var environment = localStorage.getItem('environment')
		return environmentService.getLogo(environment)
	}
  
	function handleChange(e) {
		setUsername(e.target.value)
	}

    function handleSubmit(e) {
        e.preventDefault()

        setLoading(true)

		resetPasswordService.requestPasswordChange(username).then(response => {
	        console.log(response)
	        setSubmitted(true)
	    })
	}

    return (
        <Container fluid className="auth-wrapper">
            <Row>
                <Col md={{ span: 4, offset: 4 } }>
                    <Jumbotron className="auth-inner">
	                    <AlertComponent />
	                    <img src={getLogo()} style={{width: '100%', marginBottom: '50px'}}/>
	                    { !submitted &&
	                        <Form onSubmit={handleSubmit}>
	                            <FormGroup controlId="formBasicUsername">
	                                <FormLabel>
	                                    {t('forgotpassword.label')}
	                                </FormLabel>
	                                <FormControl
										type="text"
										className="form-control"
										name="username"
										value={username}
										onChange={handleChange}
										required={true}
	                                />
	                            </FormGroup>

		                        <FormGroup controlId="formLogin">
		                            <button className="btn btn-primary btn-block" disabled={loading}>
										<span>
											{t('forgotpassword.button')}
										</span>
										{ loading && (
											<>
												&nbsp;
												<span className="spinner-border spinner-border-sm"></span>
											</>
										)}
		                            </button>
		                        </FormGroup>
		                    </Form>
	                    }
	                    { submitted &&
	                        <div>
								{t('forgotpassword.success')}
	                        </div>
	                    }
                    </Jumbotron>
                </Col>
            </Row>
        </Container>
    );
}

export default ResetPassword;