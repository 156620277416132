import api from './api.service'

export const customerMessageService = {
    getAll,
    show,
    save,
    update,
    _delete,
    markAsRead
};

const BASE_URL = '/customerMessages'

function getAll(max) {
    return api.get(BASE_URL, {max : max || 100})
}

function show(id) {
    return api.get(BASE_URL + "/" + id)
}

function save(customer) {
    return api.post(BASE_URL, customer)
}

function update(id, customer) {
    return api.put(BASE_URL + "/" + id, customer)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}

function markAsRead(id) {
	return api.get("/customerMessage/markAsRead/" + id)
}
