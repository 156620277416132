import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sv from 'date-fns/locale/sv';
import { subMonths } from 'date-fns';
import { exportService, customerService } from '../../_services'
import { history } from '../../_helpers';

const Export = () => {

	const [state, setState] = useState({ customerIds: [], startDate: subMonths(new Date(), 6), endDate: new Date(), email: '' });
	const [customers, setCustomers] = useState([]);
    const {t} = useTranslation('common');

    useEffect(() => {
		registerLocale('sv', sv)
		customerService.getAll().then(customers => {
            customers.sort((a, b) => a.name.localeCompare(b.name))
            setCustomers(customers)
            setState( prevState => ({
		        ...prevState,
		        ['customerIds']: customers.map(customer => customer.id)
		    }));
        })
    }, [])

    const onChange = (e) => {
	    const target = e.target
	    const name = target.name
	    const value = target.type === 'checkbox' ? target.checked : target.value;
	    setState( prevState => ({
	        ...prevState,
	        [name]: value
	    }));
	}

	const handleCustomerChange = (e) => {
		if (e.target.checked) {
			if (state.customerIds.indexOf(e.target.id) < 0) {
				state.customerIds.push(parseInt(e.target.id))
			}
		} else {
			state.customerIds = state.customerIds.filter(customerId => customerId != e.target.id)
		}
		setState( prevState => ({
	        ...prevState,
	        ['customerIds']: state.customerIds
	    }));
	}

	const handleStartDateChange = (date) => {
		setState( prevState => ({
	        ...prevState,
	        ['startDate']: date
	    }));
	}

	const handleEndDateChange = (date) => {
		setState( prevState => ({
	        ...prevState,
	        ['endDate']: date
	    }));
	    if (date < state.startDate) {
		    setState( prevState => ({
		        ...prevState,
		        ['startDate']: date
		    }));
	    }
	}

    const onClick = () => {
        if (!state.email) {
            return
        }

		exportService.doExport(state).then(response => {
			console.log(response)
		})
    }

	return (
		<div>
			<h2>
                {t('export.label')}
            </h2>
            <Row>
				<Col md="4">
					<Form.Group controlId="customerIds">
		                <Form.Label>
		                    {t('export.customers')}
		                </Form.Label>
		                {customers.map(customer => (
	                        <Form.Check key={customer.id} id={customer.id} type="checkbox" label={customer.name} name="customerIds" checked={state.customerIds.indexOf(customer.id) >= 0} onChange={handleCustomerChange} />
	                    ))}
		            </Form.Group>
					<Form.Group controlId="startDate">
		                <Form.Label>
		                    {t('export.startdate')}
		                </Form.Label>
		                <DatePicker
		                    selected={state.startDate}
		                    dateFormat="yyyy-MM-dd"
		                    locale="sv"
		                    onChange={handleStartDateChange}
		                    maxDate={state.endDate}
		                    className="form-control"
		                />
		            </Form.Group>
		            <Form.Group controlId="endDate">
		                <Form.Label>
		                    {t('export.enddate')}
		                </Form.Label>
		                <DatePicker
		                    selected={state.endDate}
		                    dateFormat="yyyy-MM-dd"
		                    locale="sv"
		                    onChange={handleEndDateChange}
		                    maxDate={new Date()}
		                    className="form-control"
		                />
		            </Form.Group>
					<Form.Group controlId="email">
		                <Form.Label>
		                    {t('export.email')}
		                </Form.Label>
		                <Form.Control required type="text" placeholder={t('export.email')} name="email" value={state.email} onChange={onChange} />
		            </Form.Group>
		            <div className="clearfix" style={{marginTop: '5px'}}>
			            <div style={{'float': 'right'}}>
							<Button onClick={onClick}>{t('export.export')}</Button>
						</div>
		            </div>
	            </Col>
			</Row>
        </div>
    )
}

export default Export;