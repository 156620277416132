import React, {useState} from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ModalForm from './modal'
import { useTranslation } from "react-i18next";

function AddButton(props) {

    const [show, setShow] = useState(false)
    const {t} = useTranslation('common');

    function handleConfirm (data) {
        setShow(false)
        props.confirmClick(data)
    }

    const handleShow = () => {
        console.log('handleShow')
		setShow(true)
		//document.body.className = "modal-open"
		//document.body.style = 'overflow: hidden;'
    }

    const handleClose = () => {
		setShow(false)
		document.body.className = ""
		document.body.style = '';
    }

    return (
        <>
	        <Button
	            style={{ width: "100px", float: "right" }}
	            onClick={() => setShow(true)}
	            title={t("crudtable.add")}
	            size="md"
	            color="info"
	            variant="outline-secondary">
	            {t("crudtable.add")} <FontAwesomeIcon icon={faPlus}/>
	        </Button>
	        <ModalForm
	            data={props.data}
	            show={show}
	            handleConfirm={handleConfirm}
	            handleClose={handleClose}
	            modalHeader={t("crudtable.add")}
	            modalBody={props.form}
	            size={props.size}
	        />
        </>
    )

}

export default AddButton