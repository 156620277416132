import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

function ModalForm(props) {
  const baseState = props.data
  const [state, setState] = useState(props.data)
  const [validated, setValidated] = useState(false);
  const {t} = useTranslation('common');
  const size = props.size || 'md'

  const handleChange = (e) => {
    const target = e.target
    const name = target.name
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setState( prevState => ({
        ...prevState, 
        [name]: value
    }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      props.handleConfirm(state)
      setValidated(false);
    }

  };

  function onShow() {
    setState(baseState)
	document.body.className = "modal-open"
	document.body.style = "overflow: hidden;"
  }

  return (
    <Modal
      size={size}
      show={props.show}
      backdrop={false}
      onShow={onShow}
      onHide={props.handleClose}>
	    <Modal.Header closeButton>
	        <Modal.Title>{props.modalHeader}</Modal.Title>
	        </Modal.Header>
	    <Modal.Body>{React.cloneElement(props.modalBody, { data: state, handleChange: handleChange, handleSubmit: handleSubmit, validated: validated })}</Modal.Body>
	    <Modal.Footer>
	        <Button variant="secondary" onClick={props.handleClose}>
	            {t('crudtable.cancel')}
	        </Button>
	        <Button form='data-form' type="submit" variant="primary">
	            {t('crudtable.ok')}
	        </Button>
	    </Modal.Footer>
    </Modal>
  )
}

export default ModalForm