import React from 'react'
import { Navbar, Nav, NavDropdown, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Access from './access.component';
import { useTranslation } from "react-i18next";
import { authService, environmentService } from '../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

function PageNavbar() {

	const {t} = useTranslation('common');

	const getTitle = () => {
        return <>
            <FontAwesomeIcon icon={faUser}/> {authService.getCurrentUser().username}
        </>
    }

    function getLogo() {
        var environment = localStorage.getItem('environment')
		return environmentService.getLogo(environment)
	}

    return (
        <Navbar bg="light" expand="lg">
        <Navbar.Brand as={Link} to={"/"}>
            <img src={getLogo()} style={{height: 24}}/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
                <Access permission={["ROLE_TICKET_READ"]}>
	                <Nav.Link as={Link} to={"/ticket"}>
	                    {t('navbar.ticket')}
	                </Nav.Link>
                </Access>
                <Access permission={["ROLE_CUSTOMER_MESSAGE_READ"]}>
	                <Nav.Link as={Link} to={"/customermessage"}>
	                    { authService.hasRoles(['ROLE_ADMIN']) &&
	                        t('navbar.customercommunication')
	                    }
						{ !authService.hasRoles(['ROLE_ADMIN']) &&
	                        t('navbar.communication')
	                    }
	                </Nav.Link>
                </Access>
                <Access permission={["ROLE_FORM_READ"]}>
	                <Nav.Link as={Link} to={"/form"}>
	                    {t('navbar.form')}
	                </Nav.Link>
                </Access>
                <Access permission={["ROLE_TEMPLATE_WRITE"]}>
	                <Nav.Link as={Link} to={"/template"}>
	                    {t('navbar.template')}
	                </Nav.Link>
                </Access>
                <Access permission={["ROLE_CUSTOMER_READ"]}>
	                <Nav.Link as={Link} to={"/customer"}>
	                    {t('navbar.customer')}
	                </Nav.Link>
                </Access>
                <Access permission={["ROLE_USER_READ"]}>
	                <Nav.Link as={Link} to={"/user"}>
	                    {t('navbar.user')}
	                </Nav.Link>
                </Access>
                <Access permission={["ROLE_INTRO_TEXT_READ"]}>
	                <Nav.Link as={Link} to={"/introtext"}>
	                    {t('navbar.introtext')}
	                </Nav.Link>
                </Access>
                <Access permission={["ROLE_PRIVACY_POLICY_READ"]}>
	                <Nav.Link as={Link} to={"/privacypolicy"}>
	                    {t('navbar.privacypolicy')}
	                </Nav.Link>
                </Access>
                <Access permission={["ROLE_ADMIN"]}>
	                <Nav.Link as={Link} to={"/export"}>
	                    {t('navbar.export')}
	                </Nav.Link>
                </Access>
            </Nav>
            { authService.isAuthenticated() &&
	            <Form inline>
	                <Nav>
		                <NavDropdown alignRight title={getTitle()} id="basic-nav-dropdown">
		                    <NavDropdown.Item as={Link} to={"/profile"}>
		                        {t('navbar.profile')}
		                    </NavDropdown.Item>
		                    <Access permission={["ROLE_SUPER_ADMIN"]}>
			                    <NavDropdown.Item as={Link} to={"/switchuser"}>
			                        {t('navbar.switchuser')}
			                    </NavDropdown.Item>
		                    </Access>
		                    <Access permission={["ROLE_SWITCHED_USER"]}>
				                <NavDropdown.Item as={Link} to={"/switchback"}>
			                        {t('navbar.switchback')}
			                    </NavDropdown.Item>
	                        </Access>
		                    <NavDropdown.Item as={Link} to={"/logout"}>
		                        {t('navbar.logout')}
		                    </NavDropdown.Item>
		                </NavDropdown>
	                </Nav>
	            </Form>
            }
        </Navbar.Collapse>
        </Navbar>
    )
}

export default PageNavbar;