import axios from 'axios'
import { SERVER_URL } from '../config';
import { userActions, alertActions } from '../_actions';
import { store } from '../_helpers'
import { history, authHeader } from '../_helpers';

const defaults = {
    baseURL: SERVER_URL,
    headers: () => ({
      'Content-Type': 'application/json',
      'Authorization': authHeader()
    })
  };
  
  const api = (method, url, variables) =>
    new Promise((resolve, reject) => {

      axios({
        url: `${defaults.baseURL}${url}`,
        method,
        headers: defaults.headers(),
        params: method === 'get' ? variables : undefined,
        data: method !== 'get' ? variables : undefined,
        //paramsSerializer: objectToQueryString,
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
            if (!error.response) {
              store.dispatch(alertActions.error('alerts.errorconnectionnotfound'))
            } else if (error.response.status === 500) {
              store.dispatch(alertActions.error('alerts.servererror', [error.response.data.message]))
            } else if (error.response.status === 422) {
              store.dispatch(alertActions.error('alerts.errorunprocessableentity', [error.response.data.message]))
            } else if (error.response.status === 401) {
              store.dispatch(userActions.logout())
            } else if (error.response.status === 403) {
			  history.push("/forbidden")
            } else if (error.response.status === 400) {
              store.dispatch(alertActions.error('alerts.servererror', [error.response.data]))
            } else {
              store.dispatch(userActions.logout())
            }
        },
      );
    });

  export default {
    get: (...args) => api('get', ...args),
    post: (...args) => api('post', ...args),
    put: (...args) => api('put', ...args),
    patch: (...args) => api('patch', ...args),
    delete: (...args) => api('delete', ...args),
  };