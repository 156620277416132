import React, {useState, useEffect} from 'react';
import CrudTable from '../table/crudtable'
import { customerService, userService } from '../../_services'
import CustomerForm from './customerform.component'
import { useTranslation } from "react-i18next";

function Customer() {

  const [users, setUsers] = useState([])
  const [usersDownloaded, setUsersDownloaded] = useState(false)
  const {t} = useTranslation('common');

  const initialState = {
      name: '',
      customerAdministrators: []
  }

	useEffect(() => {
        userService.getAll().then(users => {
            setUsers(users)
            setUsersDownloaded(true)
        })
    }, [])

  const usersFormatter = function(cell) {
    if (cell && cell.length > 0) {
        var usernames = []
        cell.forEach(user => {
            if (user.id != 1) {
                usernames.push(user.username)
            }
	    })
	    if (usernames.length > 0) {
	        return usernames.join(', ')
	    }
    }
    return '-'
  }

  const mainAdministratorFormatter = function(cell) {
    if (cell) {
        var username = '-'
        var user = users.find(user => user.id == cell)
        if (user) {
            return user.username
        }
    }
    return '-'
  }

  const customerAdministratorsFormatter = function(cell) {
    if (cell && cell.length > 0) {
        var usernames = []
        cell.forEach(customerAdministrator => {
	        var user = users.find(user => user.id === customerAdministrator.administrator)
	        if (user) {
	            usernames.push(user.username)
	        }
	    })
	    if (usernames.length > 0) {
	        return usernames.join(', ')
	    }
    }
    return '-'
  }

  const columns = [
    {
      dataField: 'name',
      text: t('customer.columns.name'),
      sort: true
    },
    {
      dataField: 'users',
      text: t('customer.columns.customerusers'),
      sort: true,
      formatter: usersFormatter
    },
    {
      dataField: 'mainAdministrator',
      text: t('customer.columns.mainadministrator'),
      sort: true,
      formatter: mainAdministratorFormatter
    },
    {
      dataField: 'customerAdministrators',
      text: t('customer.columns.administrators'),
      sort: true,
      formatter: customerAdministratorsFormatter
    }
  ]

  return (
    <div>
      <h2>
        {t('customer.label')}
      </h2>
      { usersDownloaded &&
	      <CrudTable
	        service={customerService}
	        columns={columns}
	        initialState={initialState}
	        form={<CustomerForm />}
	        sort={{dataField: 'name', order: 'asc'}}
	      />
      }
      </div>
  )
}

export default Customer;