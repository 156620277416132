import React, {useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { userService } from '../../_services'
import Select, { components } from 'react-select'

const CustomerForm = ({data, handleChange, handleSubmit, validated}) => {

    const [users, setUsers] = useState([])
    const [selectedValues, setSelectedValues] = useState([])
    const [existingCustomerAdministrators, setExistingCustomerAdministrators] = useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
        userService.getAll().then(users => {
            setSelectedValues(data.customerAdministrators.map(customerAdministrator => ({id: customerAdministrator.administrator, username: users.find(user => user.id === customerAdministrator.administrator)}.username)))
            setUsers(users)
            //setAdmins(users.filter(user => user.customer == 1))
        })
        setExistingCustomerAdministrators(data.customerAdministrators)
    }, [])

    function onChange(e) {
		setSelectedValues(e)
		var customerAdministrators = e.map(user => ({customer: -1, administrator: user.id}))
		if (data.customerAdministrators && data.customerAdministrators.length > 0) {
			customerAdministrators.forEach(customerAdministrator => {
				var existingCustomerAdministrator = existingCustomerAdministrators.find(existingCustomerAdministrator =>
					existingCustomerAdministrator.administrator == customerAdministrator.administrator
				)
				if (existingCustomerAdministrator) {
					customerAdministrator.id = existingCustomerAdministrator.id
				}
			})
		}
		handleChange({target: {type: "select", name: "customerAdministrators", value: customerAdministrators}})
	}

	function onMainAdministratorChange(e) {
		handleChange(e)
		var newSelectedValues = selectedValues.filter(user => user.id != e.target.value)
		setSelectedValues(newSelectedValues)
		var customerAdministrators = data.customerAdministrators.filter(customerAdministrator => customerAdministrator.administrator != e.target.value)
		handleChange({target: {type: "select", name: "customerAdministrators", value: customerAdministrators}})
	}

    const NoOptionsMessage = props => {
		return (
			<components.NoOptionsMessage {...props}>
				<span className="custom-css-class">{t('member.nousers')}</span>
			</components.NoOptionsMessage>
		);
	};

	const getMainAdmins = () => {
		return users.filter(user => user.customer == 1)
	};

	const getAdmins = () => {
		return users.filter(user => user.customer == 1 && user.id != data.mainAdministrator)
	};

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={validated}>
            <Form.Group controlId="name">
                <Form.Label>
                    {t('customer.columns.name')} *
                </Form.Label>
                <Form.Control required type="text" placeholder={t('customer.columns.name')} name="name" value={data.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="mainAdministrator">
                <Form.Label>
                    {t('customer.columns.mainadministrator')} *
                </Form.Label>
                <Form.Control required as="select" name="mainAdministrator" value={data.mainAdministrator || ''} onChange={onMainAdministratorChange}>
					<option value="">{t('customer.choosemainadministrator')}</option>
					{ getMainAdmins().map(user => {
						return <option key={user.id} value={user.id}>{user.username}</option>
					})}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="administrators">
                <Form.Label>
                    {t('customer.columns.administrators')}
                </Form.Label>
                <Select
					placeholder={t('member.selectmembers')}
					isLoading={users.length === 0}
					options={getAdmins()}
					value={selectedValues}
					getOptionLabel={user => user.username}
					getOptionValue={user => user.id}
					isMulti={true}
					closeMenuOnSelect={false}
					onChange={onChange}
					components={{ NoOptionsMessage }}/>
            </Form.Group>
        </Form>
    )
}

export default CustomerForm