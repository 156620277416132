import React, {useState, useEffect} from 'react'
import { Form, Alert } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { authService, customerMessageService, customerService, userService, fileService, ticketService } from '../../_services'
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';
import Select, { components } from 'react-select'

const CustomerMessageForm = (props) => {

	const state = props.data
	const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const [customers, setCustomers] = useState([])
    const [users, setUsers] = useState([])
    const [selectedValues, setSelectedValues] = useState([])
    const [showUsersWarning, setShowUsersWarning] = useState(false)
    const [tickets, setTickets] = useState([])
    const [files, setFiles] = React.useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
        if (authService.hasRoles(['ROLE_ADMIN'])) {
            customerService.getAll().then(customers => {
                customers = customers.filter(customer => customer.id != 1)
                customers.sort((a, b) => a.name.localeCompare(b.name));
	            if (customers.length > 0) {
	                handleChange({target: {type: "select", name: "customerId", value: customers[0].id}})
	            }
	            setCustomers(customers)
	        })
	        userService.getAll().then(users => {
                setUsers(users)
	        })
        } else {
            setTimeout(() => {
                handleChange({target: {type: "object", name: "customerId", value: authService.getCurrentUser().customerId}})
                handleChange({target: {type: "object", name: "type", value: 'FROM_CUSTOMER'}})
            }, 500)
        }
        setTimeout(() => handleChange({target: {type: "object", name: "userId", value: authService.getCurrentUser().id}}), 500)
        ticketService.getAll().then(tickets => {
            tickets.sort((a, b) => a.name.localeCompare(b.name))
            setTickets(tickets)
        })
    }, [])

    const filterTickets = () => {
        return tickets.filter(ticket => ticket.customerId == state.customerId)
	}

	const filterUsers = () => {
        return users.filter(user => user.customer == state.customerId)
	}

    const onSubmit = (event) => {
        if (!state.allUsers && state.customerMessageUsers.length == 0) {
            event.preventDefault()
            event.stopPropagation()
            setShowUsersWarning(true)
            return false
        }
        handleSubmit(event)
	}

	const handleCustomerChange = (e) => {
		handleChange(e)
        handleChange({target: {type: "select", name: "ticket", value: null}})
        handleChange({target: {type: "select", name: "customerMessageUsers", value: []}})
        setSelectedValues([])
	}

    const handleFileChange = (e) => {
		var files = []
		for (var i = 0; i < e.target.files.length; i++) {
			var targetFile = e.target.files[i]
			console.log(targetFile)
			const file = {
				id: uuidv4(),
				customerId: state.customerId,
				name: targetFile.name,
				data: null,
				dataType: targetFile.type
			}
			console.log(file)
			files.push(file)
			const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                let binaryString = event.target.result;
                file.data = btoa(binaryString)
            });
			reader.readAsBinaryString(targetFile)
		}
		handleChange({target: {type: "object", name: "files", value: files}})
		//setFiles(files)
	}

	const handleCustomerMessageUserChange = (e) => {
		setShowUsersWarning(false)
		setSelectedValues(e)
		handleChange({target: {type: "select", name: "customerMessageUsers", value: e.map(user => ({user: user.id, username: user.username}))}})
	}

    return (
        <Form id="data-form" noValidate onSubmit={onSubmit} validated={props.validated}>
            { authService.hasRoles(['ROLE_ADMIN']) &&
                <>
	                <Form.Group controlId="customer">
	                    <Form.Label>
		                    {t('customermessage.columns.customer')} *
		                </Form.Label>
		                <Form.Control required as="select" name="customerId" value={state.customerId} onChange={handleCustomerChange} disabled={state.id}>
							{ customers.sort(customer => customer.name).map(customer => (
		                        <option key={customer.id} value={customer.id}>{customer.name}</option>
		                    ))}
		                </Form.Control>
	                </Form.Group>
	                <Form.Group controlId="allUsers">
		                <Form.Check
							name="allUsers"
							type="checkbox"
							label={t('customermessage.columns.allusers')}
							checked={state.allUsers}
							onChange={handleChange}
						/>
	                </Form.Group>
	                { !state.allUsers &&
	                    <>
		                    <Form.Group controlId="users">
			                    <Form.Label>
				                    {t('customermessage.columns.users')} *
				                </Form.Label>
				                <Select
									placeholder={t('customermessage.columns.users')}
									options={filterUsers()}
									value={selectedValues}
									getOptionLabel={user => user.username}
									getOptionValue={user => user.id}
									isMulti={true}
									closeMenuOnSelect={false}
									onChange={handleCustomerMessageUserChange}/>
			                </Form.Group>
			                { showUsersWarning &&
								<Alert variant="danger">
									{t('customermessage.error.nousers')}
								</Alert>
			                }
		                </>
	                }
                </>
            }
            <Form.Group controlId="ticket">
                <Form.Label>
                    {t('customermessage.columns.ticket')}
                </Form.Label>
                <Form.Control as="select" name="ticket" value={state.ticket} onChange={handleChange} disabled={filterTickets().length == 0}>
                    <option value={''}>{t('customermessage.chooseticket')}</option>
					{ filterTickets().map(ticket => (
                        <option key={ticket.id} value={ticket.id}>{ticket.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="title">
                <Form.Label>
                    {t('customermessage.columns.title')} *
                </Form.Label>
                <Form.Control required type="text" placeholder={t('customermessage.columns.title')} name="title" value={state.title} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="message">
                <Form.Label>
                    {t('customermessage.columns.message')} *
                </Form.Label>
                <TextareaAutosize required className="form-control" minRows={3} placeholder={t('customermessage.columns.message')} name="message" value={state.message} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="file">
                <Form.Label>
                    {t('customermessage.columns.files')}
                </Form.Label>
                <Form.Control type="file" multiple onChange={handleFileChange} style={{width: 'auto'}}/>
            </Form.Group>
        </Form>
    )
}

export default CustomerMessageForm